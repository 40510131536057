import JobMiniListing from "../listings/JobMiniListing";
import PostMainListing from "../listings/PostMainListing";
import PostMiniListing from "../listings/PostMiniListing";
import ProductMiniListing from "../listings/ProductMiniListing";
import ShowMiniListing from "../listings/ShowMiniListing";
import { SignupMessage, SocialMediaMessage } from "./StaticMessages";
import Link from "next/link";
import React from "react";

// eslint-disable-next-line react/display-name
const FeedListTwo = React.memo(({ list, index, user }) => {
  const postsFirst = list?.filter((c, i) => c.contentType === "post" && i < 2);
  const postsSecond = list?.filter(
    (c, i) => c.contentType === "post" && i === 2
  );
  const postsThird = list?.filter(
    (c, i) => c.contentType === "post" && i === 3
  );
  const products = list?.filter((c) => c.contentType === "product");
  const upComingShows = list?.filter((c) => c.contentType === "trade_show");
  const upcomingJobs = list?.filter((c) => c.contentType === "job");

  return (
    <div className="flex flex-col gap-8">
      {/* Upcoming Trade Shows */}
      {upComingShows?.length > 0 && (
        <div>
          <div className="flex items-center justify-between px-6 py-3 mb-3 border border-gray-200 rounded-md">
            <div>Yaklaşan Fuarlar</div>
            <Link
              href="/tarim-fuarlari-takvimi"
              className="px-2 py-1 text-xs rounded-md bg-slate-100 text-slate-500"
            >
              Tümünü Gör
            </Link>
          </div>
          <div className="flex flex-col gap-8">
            {upComingShows?.map((show) => {
              return (
                <ShowMiniListing
                  key={show.id}
                  title={show.title}
                  slug={show.slug}
                  starting_date={show.starting_date}
                  is_national={show.is_national}
                  city={show.city}
                  country={show.country}
                  locale="tr"
                />
              );
            })}{" "}
          </div>
        </div>
      )}
      {/* Upcoming Jobs */}
      {upcomingJobs?.length > 0 && (
        <div>
          <div className="flex items-center justify-between px-6 py-3 mb-3 border border-gray-200 rounded-md">
            <div>Yakında Girilen İş İlanları</div>
            <Link
              href="/is-ilanlari"
              className="px-2 py-1 text-xs rounded-md bg-slate-100 text-slate-500"
            >
              Tümünü Gör
            </Link>
          </div>
          <div className="flex flex-col gap-8">
            {upcomingJobs?.map((job) => {
              return (
                <JobMiniListing
                  key={job.id || (Math.random() * 100).toString()} //TODO: Remove this key
                  title={job.title}
                  slug={job.slug}
                  date_created={job.date_created}
                  city={job.city}
                  views={job.views}
                  job_applications={job.job_applications.length}
                  company_logo={job.company.company_logo}
                  locale="tr"
                  status="published"
                />
              );
            })}
          </div>
        </div>
      )}
      {/* First Posts  */}
      {postsFirst?.map((post, index) => {
        if (index === 0) {
          return (
            <PostMainListing
              key={post.id}
              title={post.title}
              description={post.description}
              image={post.image}
              link={"/yazilar/" + post.slug}
              date={post.date_created}
              author={
                post.type === "company"
                  ? {
                      title: post.company.title,
                      avatar: post.company.company_logo,
                      city: post.company.city,
                      country: post.company.country,
                    }
                  : post.author
              }
              authorType={post.type}
              category={post.tags.reduce((acc, tag, index) => {
                if (index === 0) {
                  return tag.categories_id.title;
                } else {
                  return acc;
                }
              }, "")}
              likeCount={post.like_count}
              commentCount={post.comments.length}
              locale={"tr"}
            />
          );
        } else {
          return (
            <PostMiniListing
              title={post.title}
              slug={post.slug}
              category={post.tags.reduce((acc, tag, index) => {
                if (index === 0) {
                  return tag.categories_id.title;
                } else {
                  return acc;
                }
              }, "")}
              image={post.image}
              locale="tr"
              date={post.date_created}
              key={post.id}
              description={post.description}
            />
          );
        }
      })}
      {/* Messages */}
      {index % 4 === 0 && !user && <SignupMessage />}
      {index % 6 === 1 && <SocialMediaMessage />}
      {/* Second Post */}
      {postsSecond?.map((post) => {
        return (
          <PostMiniListing
            title={post.title}
            slug={post.slug}
            category={post.tags.reduce((acc, tag, index) => {
              if (index === 0) {
                return tag.categories_id.title;
              } else {
                return acc;
              }
            }, "")}
            image={post.image}
            locale="tr"
            date={post.date_created}
            key={post.id}
            description={post.description}
          />
        );
      })}
      <div className="grid grid-cols-1 gap-8 sm:grid-cols-2">
        {products?.map((product) => {
          return (
            <ProductMiniListing
              key={product.id}
              id={product.id}
              title={product.title}
              slug={product.slug}
              subcategory={product.subcategory.title}
              company={product.company.title}
              companyID={product.company.id}
              image={product.mini_image}
              score={1}
              companyScore={1}
            />
          );
        })}
      </div>
      {/* Second Post */}
      {postsThird?.map((post) => {
        return (
          <PostMiniListing
            title={post.title}
            slug={post.slug}
            category={post.tags.reduce((acc, tag, index) => {
              if (index === 0) {
                return tag.categories_id.title;
              } else {
                return acc;
              }
            }, "")}
            image={post.image}
            locale="tr"
            date={post.date_created}
            key={post.id}
            description={post.description}
          />
        );
      })}
    </div>
  );
});

export default FeedListTwo;
