/* eslint-disable react-hooks/exhaustive-deps */
import useFeed from "../../../hooks/useFeed";
import useUser from "../../../hooks/useUser";
import ContentListLoader from "../../elements/loaders/ContentListLoader";
import FeedListOne from "./FeedListOne";
import FeedListThree from "./FeedListThree";
import FeedListTwo from "./FeedListTwo";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect } from "react";
import useInView from "react-cool-inview";

const Feed = ({ initialFeedData }) => {
  const { feed, setSize, size, isValidating, loading, isEnded } = useFeed();
  const router = useRouter();
  const query = router.query;
  const { user } = useUser();
  const { observe } = useInView({
    // For better UX, we can grow the root margin so the data will be loaded earlier
    rootMargin: "200px 0px",
    // When the last item comes to the viewport
    onEnter: ({ unobserve, observe }) => {
      // Pause observe when loading data
      unobserve();
      // Load more data
      setSize(size + 1);
      // Shallow routing
      router.push(`/?p=${size + 1}`, undefined, { shallow: true });
      if (!isEnded) observe();
    },
  });

  // Set the Feed Page Number on Initial Load
  useEffect(() => {
    if (query.p) {
      setSize(Number(query.p));
    }
  }, []);

  return (
    <div className="flex flex-col gap-8">
      {/* Feed Lists */}
      {initialFeedData && <FeedListOne index={1} list={initialFeedData} />}
      {!loading &&
        feed.map((list, index) => {
          if (index > 0) {
            return index % 3 === 0 ? (
              <FeedListOne key={index} index={index} list={list} />
            ) : index % 3 === 1 ? (
              <FeedListTwo key={index} index={index} list={list} user={user} />
            ) : (
              <FeedListThree key={index} index={index} list={list} />
            );
          }
        })}
      {/* Loaders */}
      {isValidating && <ContentListLoader />}
      {/* End of the list */}
      {!loading && !isValidating && !isEnded && (
        <Link
          href={`/?p=${size + 1}`}
          className="w-full px-4 py-3 text-center text-gray-800 bg-gray-100 rounded-md"
          rel="next"
          ref={observe}
          onClick={(e) => {
            e.preventDefault();
            setSize(size + 1);
            router.push(`/?p=${size + 1}`, undefined, { shallow: true });
          }}
        >
          Daha Fazla
        </Link>
      )}
    </div>
  );
};

export default Feed;
