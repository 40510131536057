import CompanyLogoSliderItem from "./CompanyLogoSliderItem";
import { useRef } from "react";
import { useEffect, useState } from "react";

const CompanyLogoSlider = ({ data, aboveFold = true }) => {
  const containerRef = useRef(null);
  const [hover, setHover] = useState(false);

  useEffect(() => {
    if (containerRef && !hover) {
      const interval = setInterval(() => {
        if (
          containerRef?.current?.scrollWidth -
            containerRef?.current?.offsetWidth >=
          containerRef?.current?.scrollLeft + 5
        ) {
          containerRef.current.scrollLeft +=
            containerRef.current.offsetWidth / data?.lenght || 90;
        }
        // If it's last item, scroll to the first one
        else {
          containerRef.current.scrollLeft = 0;
        }
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [containerRef, hover, data]);

  return (
    <div
      ref={containerRef}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className="flex items-center gap-8 pb-6 mb-10 overflow-x-scroll border-b snap-x scroll-smooth no-scrollbar border-b-gray-100"
    >
      {data &&
        data.map((company) => (
          <CompanyLogoSliderItem
            key={company.id}
            company={company}
            aboveFold={aboveFold}
          />
        ))}
    </div>
  );
};

export default CompanyLogoSlider;
