import axios from "axios";
import useInView from "react-cool-inview";

const ImpressionObserver = ({ children, id, score, companyScore, company }) => {
  const { observe } = useInView({
    // For an element to be considered "seen", we'll say it must be 100% in the viewport
    threshold: 1,
    onEnter: async ({ unobserve }) => {
      // Stop observe when the target enters the viewport, so the callback only triggered once
      unobserve();
      // Fire an analytic event to your tracking service
      try {
        // We divide the score by 10 to decrease the impact of the impression
        const getFinalScore = (score) => 1 + score / 10;
        await axios.post(
          process.env.NEXT_PUBLIC_DIRECTUS_URL + "/impression/product",
          {
            id,
            value: 1 / getFinalScore(score) / companyScore,
          }
        );

        await axios.post("/api/impression", {
          item: id,
          type: "product",
          company,
        });
      } catch (error) {
        console.log(error);
      }
    },
  });

  return <div ref={observe}>{children}</div>;
};

export default ImpressionObserver;
