import PostMainListing from "../listings/PostMainListing";
import PostMiniListing from "../listings/PostMiniListing";
import ProductMiniListing from "../listings/ProductMiniListing";
import React from "react";

// eslint-disable-next-line react/display-name
const FeedListOne = React.memo(({ list }) => {
  const posts = list?.filter((c) => c.contentType === "post");
  const products = list?.filter((c) => c.contentType === "product");

  return (
    <div className="flex flex-col gap-8">
      {posts?.map((post, index) => {
        if (index === 0 || index === 2) {
          return (
            <PostMainListing
              key={post.id}
              title={post.title}
              description={post.description}
              image={post.image}
              link={"/yazilar/" + post.slug}
              date={post.date_created}
              author={
                post.type === "company"
                  ? {
                      title: post.company.title,
                      avatar: post.company.company_logo,
                      city: post.company.city,
                      country: post.company.country,
                    }
                  : post.author
              }
              authorType={post.type}
              category={post.tags.reduce((acc, tag, index) => {
                if (index === 0) {
                  return tag.categories_id.title;
                } else {
                  return acc;
                }
              }, "")}
              likeCount={post.like_count}
              commentCount={post.comments.length}
              locale={"tr"}
            />
          );
        } else {
          return (
            <PostMiniListing
              title={post.title}
              slug={post.slug}
              category={post.tags.reduce((acc, tag, index) => {
                if (index === 0) {
                  return tag.categories_id.title;
                } else {
                  return acc;
                }
              }, "")}
              image={post.image}
              locale="tr"
              date={post.date_created}
              key={post.id}
              description={post.description}
            />
          );
        }
      })}
      <div className="grid grid-cols-1 gap-8 sm:grid-cols-2">
        {products?.map((product) => {
          return (
            <ProductMiniListing
              key={product.id}
              id={product.id}
              title={product.title}
              slug={product.slug}
              subcategory={product.subcategory.title}
              company={product.company.title}
              companyID={product.company.id}
              image={product.mini_image}
              score={1}
              companyScore={1}
            />
          );
        })}
      </div>
    </div>
  );
});

export default FeedListOne;
