import { countries } from "../../../constants/selectLists";
import ListingTagsList from "./ListingTagsList";
import Link from "next/link";

const ShowMiniListing = ({
  slug,
  title,
  is_national,
  city,
  country,
  starting_date,
  locale,
}) => {
  return (
    <Link
      href={"/fuarlar/" + slug}
      className="flex gap-4 px-6 py-4 transition-colors duration-100 ease-out border border-gray-200 rounded-md cursor-pointer sm:gap-6 hover:border-primaryLight"
    >
      <div>
        <div>
          <ListingTagsList
            category={
              locale === "tr" ? (is_national ? "Yurtiçi" : "Yurtdışı") : ""
            }
            date={starting_date}
            locale={locale}
            type="FUAR"
          />
        </div>
        <div className="font-semibold text-lg mt-1">{title}</div>
        <div className="text-sm text-neutral-400">
          {city +
            ", " +
            countries[locale].find((c) => c.value === country).label}
        </div>
      </div>
    </Link>
  );
};

export default ShowMiniListing;
