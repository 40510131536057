import axios from "axios";
import useSWRInfinite from "swr/infinite";

const useFeed = () => {
  // Feed Fetcher
  const fetcher = async (key) => {
    try {
      const { data } = await axios.get(
        process.env.NEXT_PUBLIC_DIRECTUS_URL + key
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  // Get key
  const getKey = (pageIndex, previousPageData) => {
    if (previousPageData && !previousPageData.length) return null; // reached the end
    return `/feed?page=${pageIndex}`; // SWR key
  };

  // Feed SWR Hook
  const {
    data: feed,
    error,
    isValidating,
    mutate,
    size,
    setSize,
  } = useSWRInfinite(getKey, fetcher, {
    initialSize: 2,
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  return {
    feed,
    size,
    setSize,
    mutate,
    error,
    isValidating,
    loading: !feed && !error,
    isEnded: feed && feed[feed.length - 1]?.length < 2,
  };
};

export default useFeed;
